import React from 'react'
import Layout from '@/layouts/MediaLibraryLayout/MediaLibraryLayout'

import MediaLibrary from '../components/MediaLibrary/MediaLibrary'
const medialibrary = ({ location }) => {
  return (
    <Layout location={location}>
      <MediaLibrary />
    </Layout>
  )
}

export default medialibrary
