import React from 'react'
import classes from './MediaLibrary.module.css'
import clover from '../../images/svg/clover.svg'
import brain from '../../images/svg/brain.svg'
import gear from '../../images/svg/gear.svg'
import yufu from '../../images/svg/yufu.svg'
import nauka from '../../images/svg/nauka.svg'
import bank from '../../images/svg/bank.svg'
import { Link } from '../../components/Link'
const MediaLibrary = () => {
  return (
    <div className={classes.container}>
      <h2 className={classes.media}>Медиатека</h2>
      <div className={classes.container__card}>
        <Link to='/medialibraryphoto/' className={classes.card}>
          <div className={classes.card}>
            <span className={classes.card__text}>Фото</span>
          </div>
        </Link>
        <Link to='/medialibraryvideo/' className={classes.video}>
          <div className={classes.video}>
            <span className={classes.video__text}>Видео</span>
          </div>
        </Link>
        <Link to='/lectures' className={classes.lecture}>
          <div className={classes.lecture}>
            <span className={classes.lecture__text}>Лекции</span>
          </div>
        </Link>
      </div>
      {/* TODO: Maybe need in the future */}
      {/* <h3 className={classes.title}>XIV Фестиваль науки Юга России</h3> */}
      {/* <div className={classes.technologies}>
        <div className={classes.technology}>
          <img src={clover} alt='clover' />
          <span>Природа</span>
        </div>
        <div className={classes.technology}>
          <img src={brain} alt='brain' />
          <span>Человек</span>
        </div>
        <div className={classes.technology}>
          <img src={gear} alt='gear' />
          <span>Технологии</span>
        </div>
      </div>
      <div className={classes.icons}>
        <img className={classes.icons__yufu} src={yufu} alt='yufu' />
        <img className={classes.icons__nauka} src={nauka} alt='nauka' />
        <img className={classes.icons__bank} src={bank} alt='bank' />
      </div> */}
    </div>
  )
}

export default MediaLibrary
